import { LoadingButton } from '@mui/lab';
import { Event, useActivityLogNew } from 'api/siteMetrics';
import { Table } from 'component/base/Table';
import { TableColumnType } from 'component/base/Table/types';
import { usePaginatedListState } from 'component/hooks/usePaginatedListState';
import { useTranslation } from 'react-i18next';
import { TimeCell } from '../common/TimeCell';
import { AuthorCell } from './AuthorCell';
import { DurationSelect } from '../../DurationSelect';
import { useGetSiteSettings, useSiteSetActivityLoggingState } from 'api/site';
import { EmptyState } from 'component/new_design/base/EmptyState';
import ClipboardText from 'component/new_design/icons/ClipboardText.svg?react';
import { useEffect, useState } from 'react';

interface ActivityLogTableProps {
  readonly siteId: string;
}

export const ActivityLogTable = ({ siteId }: ActivityLogTableProps) => {
  const { t } = useTranslation();

  const tableState = usePaginatedListState(
    { filterKeys: ['duration'] },
    { filters: { duration: '1h' } }
  );

  const siteSettings = useGetSiteSettings(siteId);
  const isEnabled = !!siteSettings.data?.data.result.wp_activity_log;

  // Local state to avoid flickering when enabling/disabling activity logging
  const [isEnabledLocal, setIsEnabledLocal] = useState(isEnabled);
  useEffect(() => {
    setIsEnabledLocal(isEnabled);
  }, [isEnabled]);

  const setActivityLoggingState = useSiteSetActivityLoggingState(siteId);

  const { isLoading, data } = useActivityLogNew(siteId, tableState, isEnabled);

  const isLoadingAny =
    isLoading ||
    siteSettings.isLoading ||
    setActivityLoggingState.isPending ||
    isEnabled !== isEnabledLocal;

  const columns: TableColumnType<Event>[] = [
    {
      label: t('date'),
      key: 'created_at',
      renderValue: event => <TimeCell value={event.created_at} enableRelativeTime />,
      width: 2,
    },
    {
      label: t('author'),
      key: 'author',
      renderValue: event => <AuthorCell event={event} />,
      width: 2,
    },
    {
      label: t('ip_address'),
      key: 'ip',
      width: 2,
    },
    {
      label: t('type'),
      key: 'type',
    },
    {
      label: t('label'),
      key: 'label',
    },
    {
      label: t('action'),
      key: 'action',
    },
    {
      label: t('description'),
      key: 'description',
      width: 3,
      minWidth: '10em',
    },
  ];

  const noDataTitle =
    tableState.params.filters.duration === '30d'
      ? t('no_data_yet')
      : t('no_results_during_this_timeframe');
  const noDataDescription = isEnabled
    ? t('activity_log_is_enabled')
    : t('activity_log_is_not_enabled');

  const handleToggleEnabled = async () => {
    setIsEnabledLocal(!isEnabledLocal);
    await setActivityLoggingState.mutateAsync(!isEnabledLocal);
  };

  return (
    <Table
      title={t('activity_log')}
      label={t('activity_log')}
      columns={columns}
      data={data?.data.result || []}
      totalRowCount={data?.data.metadata?.total || 0}
      isLoading={isLoading}
      state={tableState}
      searchPlaceholder={t('search_logs')}
      enableSearch
      enablePagination
      emptyState={
        <EmptyState
          icon={<ClipboardText />}
          title={noDataTitle}
          description={noDataDescription}
          action={
            <>
              <LoadingButton
                variant="contained"
                onClick={handleToggleEnabled}
                loading={isLoadingAny}
              >
                {isEnabled ? t('disable') : t('enable')}
              </LoadingButton>
              <DurationSelect
                value={tableState.params.filters.duration}
                onChange={d => tableState.setFilter('duration', d)}
              />
            </>
          }
        />
      }
    >
      <DurationSelect
        value={tableState.params.filters.duration}
        onChange={d => tableState.setFilter('duration', d)}
      />
      <LoadingButton
        variant="contained"
        onClick={() => setActivityLoggingState.mutateAsync(!isEnabled)}
        loading={isLoadingAny}
      >
        {isEnabled ? t('disable') : t('enable')}
      </LoadingButton>
    </Table>
  );
};
