import { styled } from '@mui/material/styles';
import {
  AppBar,
  Drawer,
  IconButton,
  Typography,
  Box,
  useTheme,
  Stack,
  InputAdornment,
  FilledInput,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import useMediaQuery from '@mui/material/useMediaQuery';
import { cssVar } from 'utils/css';
import { linkHelper } from 'linkHelper';
import { useGetDrawerWidth } from '../utils/layout';
import { StoredColorMode } from '../theme';
import { Tasks } from './Tasks';
import { SidebarNavigation } from '../layout/SidebarNavigation';
import { SidebarSupport } from '../layout/SidebarSupport';

// icons
import InformationCircle from '../icons/InformationCircle.svg?react';
import SearchBig from '../icons/SearchBig.svg?react';
import SidebarLeftClose from '../icons/SidebarLeftClose.svg?react';
import UserMenu from './UserMenu';

const DrawerHeader = styled('div')({
  display: 'flex',
  flexShrink: '0',
  justifyContent: 'center',
  padding: '1.5rem 0',
});

const DrawerFooter = styled('div')({
  flexShrink: '0',
});

const LogoButton = styled(IconButton)({
  margin: '0 auto',
  padding: '0',
  cursor: 'pointer',
  '& img': {
    display: 'block',
    height: 'auto',
    margin: '0',
    maxHeight: '2.25rem',
    maxWidth: '45vw',
    width: 'auto',
  },
  '&:hover': {
    backgroundColor: 'transparent',
  },
});

interface Props {
  readonly hasData?: boolean;
  readonly colorMode: StoredColorMode;
  readonly onColorModeChange: (mode: StoredColorMode) => void;
}

export function Header({ hasData, onColorModeChange, colorMode }: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallBreakpoint = useMediaQuery(theme.breakpoints.down(1015));

  const drawerWidth = useGetDrawerWidth();

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          zIndex: theme => theme.zIndex.drawer + 1,
          width: { sm: `calc(100% - ${drawerWidth})` },
          ml: { sm: `${drawerWidth}` },
          boxShadow: 'none',
          px: 'var(--dashboard-gap)',
          py: 4,
        }}
      >
        <Stack justifyContent="space-between" spacing={3} direction="row">
          <FilledInput
            size="medium"
            fullWidth
            placeholder={t('global_search.placeholder')}
            startAdornment={
              <InputAdornment position="start">
                <SearchBig />
              </InputAdornment>
            }
            endAdornment={
              <InputAdornment position="end">
                <Stack direction="row" alignItems="center">
                  <InformationCircle />
                  <Typography variant="body2" ml={1}>
                    {t('global_search.search_tips')}
                  </Typography>
                  <Box
                    sx={{
                      lineHeight: 1,
                      backgroundColor: 'white',
                      borderRadius: cssVar('--border-radius-xs'),
                      color: 'greys.300',
                      ml: 3,
                      py: 0.5,
                      px: 1.5,
                      boxShadow:
                        '0px 4px 1px 0px rgba(0, 0, 0, 0.00), 0px 3px 1px 0px rgba(0, 0, 0, 0.01), 0px 1px 1px 0px rgba(0, 0, 0, 0.05), 0px 1px 1px 0px rgba(0, 0, 0, 0.09)',
                    }}
                  >
                    /
                  </Box>
                </Stack>
              </InputAdornment>
            }
          />
          <Tasks />
          <UserMenu hasData={hasData} colorMode={colorMode} onColorModeChange={onColorModeChange} />
        </Stack>
      </AppBar>
      {isSmallBreakpoint ? null : (
        <Drawer
          sx={{
            display: 'block',
            '&.MuiDrawer-docked': {
              display: 'flex',
              flex: '1 1 auto',
            },
            '& .MuiDrawer-paper': {
              borderRightWidth: '0',
              display: 'flex',
              width: '15rem',
            },
          }}
          variant="permanent"
          anchor="left"
          open
        >
          <DrawerHeader>
            <Stack direction="row" justifyContent="space-between" sx={{ width: '100%', mx: 3 }}>
              <LogoButton
                aria-label="logo"
                size="large"
                onClick={() => navigate(linkHelper.dashboard.getLink())}
              >
                <img src="/rocket.svg" alt="Rocket.net" />
              </LogoButton>
              <IconButton size="large" edge="end" sx={{ color: 'greys.400' }}>
                <SidebarLeftClose />
              </IconButton>
            </Stack>
          </DrawerHeader>
          <SidebarNavigation />
          <DrawerFooter sx={{ marginTop: 'auto' }}>
            <SidebarSupport />
          </DrawerFooter>
        </Drawer>
      )}
    </>
  );
}
