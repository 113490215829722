import {
  Box,
  Button,
  MenuItem,
  Menu,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Page from 'component/new_design/layout/Page';
import { Navigate, Route, Routes, useParams, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { linkHelper } from 'linkHelper';
import { Suspense, useContext, useState, lazy, MouseEvent, ReactNode } from 'react';
import { SiteLockedPopover } from 'component/partial/SiteLockedPopover';
import { PostMessageContext } from 'component/postMessage/PostMessageContext';
import { isInIframe } from 'utils/iframe';
import { SiteDetail, useSiteDetails } from 'api/site';
import { SiteSelectorMenu } from '../partial/SiteSelectorMenu';
import { isSiteAccess, useGetToken } from 'utils/token';
import { useExternalLink } from 'component/hooks/useExternalLink';
import { LoadingButton } from '@mui/lab';
import CardTab from '../partial/CardTab';
import CurrentMode from '../partial/CurrentMode';
import { getIsWpSiteControl } from 'utils';
import { SiteDetailsOverview } from '../partial/SiteDetailsOverview';
import { FileManagerTable } from '../partial/tables/file-manager/FileManagerTable';
import { SiteFileContent } from 'component/partial/SiteFileContent';
import { ActivityLogTable } from '../partial/tables/logs/ActivityLogTable';
import { AccessLogTable } from '../partial/tables/logs/AccessLogTable';
import Logs from './SiteDetails/Logs';
import Reporting from './SiteDetails/Reporting';
import { ReportingOverview } from '../partial/Reporting/ReportingOverview';
import { ReportingDelivery } from '../partial/Reporting/ReportingDelivery';
import { BackButton } from '../partial/BackButton';
// icons
import PencilEdit from '../icons/PencilEdit.svg?react';
import ClearCache from '../partial/ClearCache';
import ClipboardTextDuotone from '../icons/ClipboardTextDuotone';
import FileSettingsDuotone from '../icons/FileSettingsDuotone';
import FolderSearchDuotone from '../icons/FolderSearchDuotone';
import LayersDuotone from '../icons/LayersDuotone';
import LockCloseDuotone from '../icons/LockCloseDuotone';
import PluginDuotone from '../icons/PluginDuotone';
import ReceiptDuotone from '../icons/ReceiptDuotone';
import RocketShipDuotone from '../icons/RocketShipDuotone';
import ServerDuotone from '../icons/ServerDuotone';
import Wordpress from '../icons/Wp.svg?react';
// mobile menu icons
import FolderSearch from '../icons/FolderSearch.svg?react';
import Layers from '../icons/Layers.svg?react';
import PluginAddon from '../icons/PluginAddon.svg?react';
import FileSettings from '../icons/FileSettings.svg?react';
import Server from '../icons/Server.svg?react';
import ClipboardText from '../icons/ClipboardText.svg?react';
import Receipt from '../icons/Receipt.svg?react';
import LockClose from '../icons/LockClose.svg?react';
import RocketShip from '../icons/RocketShipSolid.svg?react';
import ChevronSortVertical from '../icons/ChevronSortVertical.svg?react';
import CheckCircle from '../icons/CheckCircle.svg?react';

const Backups = lazy(() => import('./SiteDetails/Backups'));

function TopButtons({ siteId }: { readonly siteId: string }) {
  // TODO - get this from API
  const isSiteLocked = false;
  // TODO - get this from API
  const isLoading = false;
  const { t } = useTranslation();
  const { openExternalLink } = useExternalLink();

  return (
    <Stack direction="row" gap={2}>
      <ClearCache siteId={siteId} />
      <SiteLockedPopover isSiteLocked={isSiteLocked}>
        <LoadingButton
          size="large"
          onClick={() => {
            openExternalLink({
              iframeUrl: `/manage/${siteId}/wordpress?token=${window.sessionStorage.getItem(
                'user_session'
              )}`,
              url: `/manage/${siteId}/wordpress`,
              relative: true,
            });
          }}
          variant="contained"
          color="tertiary"
          startIcon={<Wordpress />}
          fullWidth
          disabled={isSiteLocked}
          loading={isLoading}
        >
          {t('wp_admin')}
        </LoadingButton>
      </SiteLockedPopover>
    </Stack>
  );
}

function FirstRow({
  isSmallBreakpoint,
  siteId,
}: {
  readonly isSmallBreakpoint: boolean;
  readonly siteId: string;
}) {
  if (isSmallBreakpoint) {
    return (
      <Stack justifyContent="space-between" direction="row">
        <BackButton to={linkHelper.newDesign.sites.getLink()} />
        <TopButtons siteId={siteId} />
      </Stack>
    );
  }
  return <BackButton to={linkHelper.newDesign.sites.getLink()} />;
}

function SecondRow({
  siteId,
  siteDetails,
}: {
  readonly siteId: string;
  readonly siteDetails: SiteDetail | undefined;
}) {
  const theme = useTheme();
  const isSmallBreakpoint = useMediaQuery(theme.breakpoints.down('md'));
  const { getSdk } = useContext(PostMessageContext);
  const sdk = getSdk();
  const { t } = useTranslation();
  const [showSiteLabel, setShowSiteLabel] = useState(false);
  const token = useGetToken();

  const domainName = (
    <Stack
      direction="row"
      alignItems="baseline"
      sx={{ flexWrap: 'wrap', gap: 1, alignItems: 'center' }}
    >
      {sdk || isInIframe() ? (
        <Box>
          <Typography variant="h1" data-testid="domainLabel">
            {siteDetails?.domain ?? ''}
          </Typography>
        </Box>
      ) : (
        // pass wpsitecontrol
        <SiteSelectorMenu siteDetails={siteDetails} isWpSiteControl={false} />
      )}
      {!isSiteAccess(token) && !sdk ? (
        <Button onClick={() => setShowSiteLabel(true)} variant="contained" size="small">
          {siteDetails?.label ?? t('add_label')}
          {siteDetails?.label ? <PencilEdit /> : null}
        </Button>
      ) : null}
    </Stack>
  );

  if (isSmallBreakpoint) {
    return domainName;
  }

  return (
    <Stack justifyContent="space-between" direction="row">
      {domainName}
      <TopButtons siteId={siteId} />
    </Stack>
  );
}

function NavigationMenuItem({
  icon,
  title,
  to,
  onClick,
}: {
  readonly icon: ReactNode;
  readonly title: string;
  readonly to: string;
  readonly onClick: () => void;
}) {
  const { pathname } = useLocation();
  const selected = pathname.startsWith(to);
  const navigate = useNavigate();

  return (
    <MenuItem
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        navigate(to);
        onClick();
      }}
      value={to}
      selected={selected}
      sx={{
        width: '100%',
        cursor: 'pointer',
      }}
    >
      <Stack direction="row" gap={2} width="100%" justifyContent="space-between">
        <Box
          sx={theme => ({
            color: selected ? theme.palette.common.black : theme.palette.grey[400],
            width: '16px',
            height: '16px',
            '& svg': {
              width: '100%',
              height: '100%',
            },
          })}
        >
          {icon}
        </Box>
        <Typography variant="body2" flexGrow={1}>
          {title}
        </Typography>
        {selected ? (
          <Box sx={{ ml: 'auto', display: 'grid', placeItems: 'center' }}>
            <CheckCircle />
          </Box>
        ) : null}
      </Stack>
    </MenuItem>
  );
}

function Navigation({
  siteId,
  isSmallBreakpoint,
}: {
  readonly siteId: string;
  readonly isSmallBreakpoint: boolean;
}) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const navigationMenuItems = [
    {
      icon: <FolderSearch />,
      title: t('overview'),
      to: linkHelper.newDesign.sites.siteDetails.overview.getLink({ siteId }),
    },
    {
      icon: <PluginAddon />,
      title: t('plugin_plural'),
      to: linkHelper.newDesign.sites.siteDetails.plugins.getLink({ siteId }),
    },
    {
      icon: <Layers />,
      title: t('theme_plural'),
      to: linkHelper.newDesign.sites.siteDetails.themes.getLink({ siteId }),
    },
    {
      icon: <FileSettings />,
      title: t('file_manager'),
      to: linkHelper.newDesign.sites.siteDetails.fileManager.getLink({
        siteId,
      }),
    },
    {
      icon: <Server />,
      title: t('backup_plural'),
      to: linkHelper.newDesign.sites.siteDetails.backups.getLink({ siteId }),
    },
    {
      icon: <ClipboardText />,
      title: t('log_plural'),
      to: linkHelper.newDesign.sites.siteDetails.logs.getLink({ siteId }),
    },
    {
      icon: <Receipt />,
      title: t('reporting'),
      to: linkHelper.newDesign.sites.siteDetails.reporting.getLink({ siteId }),
    },
    {
      icon: <LockClose />,
      title: t('security'),
      to: linkHelper.newDesign.sites.siteDetails.security.getLink({ siteId }),
    },
    {
      icon: <RocketShip />,
      title: t('advanced'),
      to: linkHelper.newDesign.sites.siteDetails.advanced.getLink({ siteId }),
    },
  ];

  const selectedNavigationItem = navigationMenuItems.find(item => {
    return window.location.pathname.startsWith(item.to);
  });
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  if (isSmallBreakpoint) {
    return (
      <>
        <Button
          variant="contained"
          color="tertiary"
          size="large"
          fullWidth
          id="mobile-navigation-button"
          aria-controls={open ? 'mobile-navigation-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          sx={theme => ({
            justifyContent: 'left',
            color: theme.palette.greys[900],
            padding: theme.spacing(2.5),
            outline: open ? `1px solid ${theme.palette.primary.main}` : 'none',
          })}
        >
          <Stack
            direction="row"
            gap={2}
            alignItems="center"
            justifyContent="space-between"
            width="100%"
          >
            <Box
              sx={{
                width: '16px',
                height: '16px',
                '& svg': {
                  width: '100%',
                  height: '100%',
                },
              }}
            >
              {selectedNavigationItem?.icon}
            </Box>
            <Typography
              variant="body1"
              fontWeight={600}
              sx={{
                flexGrow: 1,
                textAlign: 'left',
              }}
            >
              {selectedNavigationItem?.title ?? t('site_navigation')}
            </Typography>
            <Box color="greys.500" alignSelf="end">
              <ChevronSortVertical />
            </Box>
          </Stack>
        </Button>
        <Menu
          id="mobile-navigation-menu"
          aria-labelledby="mobile-navigation-button"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          slotProps={{
            paper: {
              sx: theme => ({
                width: `calc(100vw - ${theme.spacing(8)})`,
              }),
            },
          }}
          sx={{
            marginTop: '50px',
            '&.MuiList-root': {
              paddingRight: '4px !important',
            },
          }}
        >
          {navigationMenuItems.map(item => (
            <NavigationMenuItem
              onClick={() => setAnchorEl(null)}
              key={item.to}
              icon={item.icon}
              title={item.title}
              to={item.to}
            />
          ))}
        </Menu>
      </>
    );
  }

  return (
    <Box
      sx={{
        display: 'grid',
        gap: 4,
        gridTemplateColumns: 'repeat(9, 1fr)',
      }}
      component="nav"
      aria-label={t('site_detail_navigation')}
    >
      <CardTab
        IconComponent={FolderSearchDuotone}
        title={t('overview')}
        to={linkHelper.newDesign.sites.siteDetails.overview.getLink({ siteId })}
      />
      <CardTab
        IconComponent={PluginDuotone}
        title={t('plugin_plural')}
        to={linkHelper.newDesign.sites.siteDetails.plugins.getLink({ siteId })}
      />
      <CardTab
        IconComponent={LayersDuotone}
        title={t('theme_plural')}
        to={linkHelper.newDesign.sites.siteDetails.themes.getLink({ siteId })}
      />
      <CardTab
        IconComponent={FileSettingsDuotone}
        title={t('file_manager')}
        to={linkHelper.newDesign.sites.siteDetails.fileManager.getLink({
          siteId,
        })}
      />
      <CardTab
        IconComponent={ServerDuotone}
        title={t('backup_plural')}
        to={linkHelper.newDesign.sites.siteDetails.backups.getLink({ siteId })}
      />
      <CardTab
        IconComponent={ClipboardTextDuotone}
        title={t('log_plural')}
        to={linkHelper.newDesign.sites.siteDetails.logs.getLink({ siteId })}
      />
      <CardTab
        IconComponent={ReceiptDuotone}
        title={t('reporting')}
        to={linkHelper.newDesign.sites.siteDetails.reporting.getLink({ siteId })}
      />
      <CardTab
        IconComponent={LockCloseDuotone}
        title={t('security')}
        to={linkHelper.newDesign.sites.siteDetails.security.getLink({ siteId })}
      />
      <CardTab
        IconComponent={RocketShipDuotone}
        title={t('advanced')}
        to={linkHelper.newDesign.sites.siteDetails.advanced.getLink({ siteId })}
      />
    </Box>
  );
}

export default function SiteDetails() {
  const { siteId = '' } = useParams<{ siteId: string }>();
  const { data, isLoading } = useSiteDetails({ id: siteId });
  const theme = useTheme();
  const isSmallBreakpoint = useMediaQuery(theme.breakpoints.down('md'));
  const siteDetails = data?.data.result;

  return (
    <Page>
      <Stack gap={6}>
        <FirstRow siteId={siteId} isSmallBreakpoint={isSmallBreakpoint} />
        <SecondRow siteId={siteId} siteDetails={siteDetails} />
        <CurrentMode isLoading={isLoading} siteDetails={siteDetails} />
        <Navigation siteId={siteId} isSmallBreakpoint={isSmallBreakpoint} />
        <Routes>
          <Route
            path={linkHelper.newDesign.sites.siteDetails.overview.path}
            element={
              <Suspense>
                <SiteDetailsOverview siteDetails={siteDetails} />
              </Suspense>
            }
          />
          <Route path={linkHelper.newDesign.sites.siteDetails.plugins.path} element={null} />
          <Route
            path={linkHelper.newDesign.sites.siteDetails.fileManager.path}
            element={
              <Routes>
                <Route index element={<FileManagerTable siteId={siteId} />} />
                <Route path="create" element={<SiteFileContent siteId={siteId} />} />
                <Route path="view" element={<SiteFileContent siteId={siteId} />} />
              </Routes>
            }
          />
          <Route
            path={linkHelper.newDesign.sites.siteDetails.logs.path}
            element={<Logs siteId={siteId} />}
          >
            <Route index element={<ActivityLogTable siteId={siteId} />} />
            <Route
              path={linkHelper.newDesign.sites.siteDetails.logs.accessLog.path}
              element={<AccessLogTable siteId={siteId} />}
            />
          </Route>
          <Route
            path={linkHelper.newDesign.sites.siteDetails.backups.path}
            element={
              <Suspense>
                <Backups siteId={siteId} />
              </Suspense>
            }
          />
          <Route
            path={linkHelper.newDesign.sites.siteDetails.reporting.path}
            element={<Reporting siteId={siteId} />}
          >
            <Route index element={<ReportingOverview />} />
            <Route
              path={linkHelper.newDesign.sites.siteDetails.reporting.delivery.path}
              element={<ReportingDelivery />}
            />
          </Route>
          <Route
            path="*"
            element={
              <Navigate
                to={linkHelper.newDesign.sites.siteDetails.overview.getLink({ siteId })}
                replace
              />
            }
          />
        </Routes>
      </Stack>
    </Page>
  );
}
